//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getCookie} from "tiny-cookie";
import {miceService} from "../service/miceService";
import {Field, Collapse, CollapseItem, Form, Picker} from 'vant';
export default {
    name: "addR1Demand",
    components: {
        [Field.name]: Field,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Picker.name]: Picker,
        [Form.name]: Form,
    },
    data() {
        return {
            wrapperHeight: 0,
            mettingId: '',
            eventForm: {
                amount: '',
                MettingType: '',
                otherAmount: 0,
                qty: 1,
            },
            tips: [],
            isShowPopup: false,
            eventData: {},
            comboList: [],
            activeNames: [],
            budgetItem: {}
        }
    },
    watch: {
        eventForm: {
            handler: "listenForm",
            deep: true
        },
    },
    mounted() {
        let body_h =
            this.iStorage.get("_screen_height") ||
            document.documentElement.clientHeight ||
            window.innerHeight;
        let header_h = document.getElementsByClassName("header_position")[0]
            .clientHeight;
        this.wrapperHeight = body_h - 2 * header_h;
    },
    created() {
        this.eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
        this.getTips()
    },
    methods: {
        listenForm () {
            if (this.eventForm.MettingType && this.eventForm.qty) {
                let meetingAmount = this.comboList.filter(item => item.txt == this.eventForm.MettingType)
                let amount = meetingAmount && meetingAmount[0].txt.match(/(\d+)/)[0]
                this.eventForm.amount = (Number(amount) * Number(this.eventForm.qty) + Number(this.eventForm.otherAmount || 0)).toFixed(2)
            }
        },
        handleInput(value) {
            // 将输入值限制为大于等于1的正整数
            const num = parseInt(value, 10);
            if (isNaN(num) || num < 1) {
                this.eventForm.qty = 1;
            } else {
                this.eventForm.qty = num.toString();
            }
        },
        validateQty(value) {
            // 验证输入值是否为大于等于1的正整数
            const num = parseInt(value, 10);
            return !isNaN(num) && num >= 1;
        },
        handleInputAmount(value) {
            // 将输入值限制为大于等于0并保留两位小数
            let num = parseFloat(value);
            if (isNaN(num) || num < 0) {
                this.eventForm.otherAmount = 0;
            } else {
                this.eventForm.otherAmount = num.toFixed(2);
            }
        },
        validateAmount(value) {
            // 验证输入值是否为大于等于0的数字，并保留两位小数
            const num = parseFloat(value);
            return !isNaN(num) && num >= 0 && value.match(/^\d+(\.\d{0,2})?$/);
        },
        goBack() {
            this.iStorage.set("selectItem", "");
            this.$router.back()
        },
        saveBudgetItem() {
            this.debounce(this.saveForm, 500);
        },
        //选择预算项目
        selectItem() {
            this.isShowPopup = true;
            var values = this.comboList.map(ele => {
                return ele.txt;
            });
            this.budgetItem = {
                values: values,
                defaultIndex: values.indexOf(this.eventForm.MettingType) //定位
            };
        },
        //清空
        resetPicker() {
            this.eventForm.MettingType = "";
            this.isShowPopup = false;
        },
        //监听popup
        getItem(picker, values) {
            if (values.indexOf("请选择") > -1) {
                this.eventForm.MettingType = "";
            } else {
                this.eventForm.MettingType = values;
            }
        },
        popupConfirm(data) {
            if (data.indexOf("请选择") > -1) {
                this.eventForm.MettingType = "";
            } else {
                this.eventForm.MettingType = data;
            }
            this.isShowPopup = false;
        },
        saveForm() {
            this.$refs.form.validate().then(() => {
                let eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
                let data = this.comboList.filter(item => item.txt == this.eventForm.MettingType)
                let params = {
                    MiceId: eventData.proposalId,
                    MettingType: data && data[0].val.split('/')[0],
                    MettingTypeDesc: this.eventForm.MettingType,
                    Qty: this.eventForm.qty,
                    otherAmount: this.eventForm.otherAmount,
                    Amount: this.eventForm.amount
                };
                if (this.mettingId) {
                    params.mettingId = this.mettingId
                }
                miceService.SaveItem(params).then((res) => {
                    if (res.success) {
                        this.$iToast('修改需求成功！');
                        this.$store.commit("setPublicValue", {
                            includes: " ",
                        });
                        this.goBack();
                    }
                });
            }).catch((e) => {
                console.log(e);
                this.$iToast('必填项不能为空');
            });
        },
        /** 防抖函数 */
        debounce(fn, delay) {
            // fn只执行一次，变相实现第一次执行不延时
            fn();

            this.debounce = () => {
                if (this.timer != null) {
                    clearTimeout(this.timer);
                }
                // 如果已经执行过，不再执行

                this.timer = setTimeout(fn, delay);
            };
        },
        getTips () {
          let params = {
            collection: "cfg-ievent-category",
            filter: {
              '$and' : [
                {"config.eventSize" : this.eventData.itsExtData.eventSize },
                {"config.eventType" : this.eventData.typeDictTxt},
                {"project": '视频会议'}
              ]
            },
            "projection": {
              "project": 1,
              "itsExtData": 1
            },
          }
          miceService.getsettings(params).then(res => {
            if (res && res.success && res.content) {
                this.tips = res.content.itsExtData.tips
                this.GetQueryData()
            }
          })
        },
        GetQueryData () {
            let params = {
                "code": "dict_event_onlinemeetingtypes",
                "mode": "tenant",
                "additionals": {
                    "eventType": this.eventData.typeDictTxt
                }
            }
            miceService.QuerySettings(params).then(res => {
                if (res && res.success && res.content) {
                    this.comboList = res.content
                    let data = this.iStorage.get("selectItem") || "";
                    if (data !== "") {
                        this.eventForm = {
                            amount: data.amount,
                            MettingType: data.mettingTypeDesc,
                            otherAmount: data.otherAmount,
                            qty: parseInt(data.qty, 10),
                        }
                        this.mettingId = data.mettingId
                    }
                }
            })
        }
    }
}
